const auth0 = {
  domain: "%AUTH0_DOMAIN%",
  clientId: "%AUTH0_CLIENT_ID%",
  authorizationParams: {
    audience: "%AUTH0_AUDIENCE%",
    redirect_uri: window.location.origin,
    connection: "main-tenant-oidc",
    display:
      "an-SIMARIS_curves|azure-social-common-off|sup",
  },
  applicationId: "d7c9e24f-f2f5-4c91-b72e-9a58feee2ca7",
};

export const environment = {
  production: false,
  name: "test",
  apiURL: "%SCU_API_URL%",
  appVersion: require("../../package.json").version,
  sessionTime: 60, //User session time will be 60mins
  auth: auth0,
  serviceProxyUrl: "%SERVICE_PROXY_URL%",
  region: "13",
  role: "8bd53987-d601-11ee-8b33-023f79aed663",
};
